import request from '@/plugins/axios'


// 图片列表
export const apiCommunityArticleImageLists = (params: any) => request.get('/community.community_article_image/lists', { params })

// 添加图片
export const apiCommunityArticleImageAdd = (params: any) => request.post('/community.community_article_image/add', params)

// 编辑图片
export const apiCommunityArticleImageEdit = (params: any) => request.post('/community.community_article_image/edit', params)

// 删除图片
export const apiCommunityArticleImageDelete = (params: any) => request.post('/community.community_article_image/delete', params)

// 图片详情
export const apiCommunityArticleImageDetail = (params: any) => request.get('/community.community_article_image/detail', { params })

export const apiCommunityArticleImageSwitchStatus = (params: any) => request.post('/community.community_article_image/status', params)